import React, {useEffect, useState} from "react";
import {
    Paper,
    Box,
    Button,
    ListItemText,
    ListItemSecondaryAction,
    ListItem,
    List,
    Alert, useTheme
} from "@mui/material";
import {SwapHoriz} from "@mui/icons-material";
import ChangePlan from "./ChangePlan";
import {subscriptionServices} from "../../../service/subscriptionService";
import Spinner from "../../spinner/Spinner";
import useCustomStyles from "../../utils/UseCustomStyles";

const customStyles = (theme) => ({
    root: {
        width: "100%",
        padding: "2px 0px 16px 0px",
        backgroundColor: theme.palette.background.defaultBlue,
        borderRadius: 4
    },
    paper: {
        position: "relative",
        minHeight: 120
    },
    header: {
        paddingLeft: theme.spacing(2)
    },
    currentPlanHeader: {
        fontSize: 16,
        paddingTop: 8,
        textAlign: "center"
    },
    currentPlan: {
        fontSize: 32,
        paddingTop: 20,
        paddingBottom: 6,
        textAlign: "center",
    },
    changePlanButton: {
        textAlign: "center",
        marginTop: 20
    },
    discount: {
        color: theme.palette.error.light
    }
});

function SubscriptionDetails() {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const [openChangePlan, setOpenChangePlan] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [details, setDetails] = useState(null);

    const handleCloseChangePlan = () => {
        setOpenChangePlan(false);
        setDetails(null);
    };

    const handleClickOpenChangePlan = () => {
        setOpenChangePlan(true);
    };

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            setErrorMsg("");
            await subscriptionServices.fetchSubscription("true", "false", "true").then((result) => {
                setDetails(result);
            }).catch((error) => {
                setErrorMsg(error?.response?.data?.error);
            }).finally(() => {
                setLoading(false);
            });
        }

        if (details === null) {
            fetchData();
        }
    }, [details]);

    const getOverageEvents = (max, usedEvents) => {
        if (usedEvents > max) {
            return (usedEvents - max).toLocaleString();
        }
        return 0;
    };

    const getOverageCost = (max, usedEvents, planName, overagePrice) => {
        let overageEvents = 0;
        if (usedEvents > max) {
            overageEvents = usedEvents - max;
        }

        let cost = overageEvents * (overagePrice / 1000);
        return "€" + cost.toFixed(2).toLocaleString() + " (€ " + overagePrice + " per 1000 events)";
    };

    const getMonthlyPayment = () => {
        let monthlyPayment = details?.plan?.price / 100;
        if (details?.discount?.percentOff > 0) {
            let discount = monthlyPayment - (monthlyPayment * (details?.discount?.percentOff / 100));
            return <span><span style={{textDecoration: "line-through"}}>{"€" + monthlyPayment.toFixed(2)}</span> / <span
                className={classes?.discount}>{"€" + discount.toFixed(2)}</span></span>;
        }
        return "€" + monthlyPayment.toFixed(2);
    };

    return (
        <Box className={classes?.root}>
            <Box>
                <Box>
                    {errorMsg !== "" && <Alert variant="filled" severity="error" color="error">{errorMsg}</Alert>}
                </Box>
                <Box>
                    <h3 className={classes?.header}>Details</h3>
                </Box>
                <Box>
                    <Paper className={classes?.paper} elevation={0}>
                        {loading && <Spinner overComponentBox={true} size={32} noBackShadow={true}/>}
                        {!loading &&
                            <div>
                                <Box display="flex" flexDirection="column">
                                    <Box className={classes?.currentPlanHeader}>
                                        Current plan: <b>{details?.plan?.name}</b>
                                    </Box>
                                    <Box className={classes?.currentPlan}>
                                        {"€" + (details?.plan?.price / 100).toFixed()}<span style={{fontSize: 14}}> / month</span>
                                    </Box>
                                    <Box className={classes?.changePlanButton}>
                                        <Button type="button" variant="contained" color="primary"
                                                onClick={handleClickOpenChangePlan}>
                                            <SwapHoriz style={{fontSize: 16, marginRight: 8}}/> Change Plan
                                        </Button>
                                        <ChangePlan open={openChangePlan} onClose={handleCloseChangePlan}/>
                                    </Box>
                                </Box>
                                <List style={{paddingTop: 30}}>
                                    <GenerateListItem s1="Next Issued Plan" s2={details?.plan?.name}/>
                                    <GenerateListItem s1="Monthly Payment" s2={getMonthlyPayment()}/>
                                    <GenerateListItem s1="Current Billing Period"
                                                      s2={details?.plan?.currentPeriodStart + " - " + details?.plan?.currentPeriodEnd}/>
                                    <GenerateListItem s1="Events Included"
                                                      s2={details?.limits?.maxEvents?.toLocaleString()}/>
                                    <GenerateListItem s1="Events Used" s2={details?.usedEvents?.toLocaleString()}/>
                                    <GenerateListItem s1="Overage Events"
                                                      s2={getOverageEvents(details?.limits?.maxEvents, details?.usedEvents)}/>
                                    <GenerateListItem s1="Overage Cost"
                                                      s2={getOverageCost(details?.limits?.maxEvents, details?.usedEvents, details?.plan?.name, details?.plan?.overagePrice)}/>
                                    <GenerateListItem s1="Max Users" s2={details?.limits?.maxUsers?.toLocaleString()}/>
                                    <GenerateListItem s1="Max Cost Sync Integrations"
                                                      s2={details?.limits?.maxCostSyncIntegrations?.toLocaleString()}/>
                                    <GenerateListItem s1="Max Revenue Sync Integrations"
                                                      s2={details?.limits?.maxRevenueSyncIntegrations?.toLocaleString()}/>
                                    <GenerateListItem s1="Max Server Integrations"
                                                      s2={details?.limits?.maxServerIntegrations?.toLocaleString()}/>
                                    <GenerateListItem s1="Max Custom Domains"
                                                      s2={details?.limits?.maxCustomDomains.toLocaleString()}/>
                                    <GenerateListItem s1="Max Entries"
                                                      s2={details?.limits?.maxEntries.toLocaleString()}/>
                                    <GenerateListItem s1="Max Website Domains"
                                                      s2={details?.limits?.maxWebsiteDomains.toLocaleString()}/>
                                    <GenerateListItem s1="Max Website S3 Buckets"
                                                      s2={details?.limits?.maxWebsiteBuckets.toLocaleString()}/>
                                    <GenerateListItem s1="Max Website Deploys"
                                                      s2={details?.limits?.maxWebsiteBuckets.toLocaleString()}/>
                                </List>
                            </div>
                        }
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
}

function GenerateListItem(props) {
    const {s1, s2} = props;
    return (
        <ListItem divider>
            <ListItemText primary={s1} disableTypography style={{fontWeight: 600}}/>
            <ListItemSecondaryAction>
                {s2}
            </ListItemSecondaryAction>
        </ListItem>
    );
}

export default SubscriptionDetails;