import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, FormGroup, TextField, InputAdornment} from "@mui/material";
import {Remove, Add} from "@mui/icons-material";
import {debounce} from "lodash";

export default function TrafficSourceChangeBid({row, handleChangeBid}) {

    const [bid, setBid] = useState(0.0000);
    const [changeBid, setChangeBid] = useState(false);

    const handleInputChange = (e) => {
        let n = e?.target?.value;
        let reg = /^\d*\.?\d*$/
        if (n.match(reg)) {
            setBid(n);
            setChangeBid(true);
        }
    };

    const handleIncrement = () => {
        let n = parseFloat(bid) + 0.01;
        if (isNaN(n)) {
            n = 0.01;
        }
        n = n.toFixed(4);
        setBid(n);
        setChangeBid(true);
    };

    const handleDecrement = () => {
        if (bid > 0) {
            let n = parseFloat(bid) - 0.01;
            if (n < 0) {
                n = 0;
            }
            n = n.toFixed(4);
            setBid(n);
            setChangeBid(true);
        }
    };

    const formatInput = () => {
        let n = parseFloat(bid);
        if (isNaN(n)) {
            n = 0;
        }
        n = n.toFixed(4);
        setBid(n);
    };

    const handleBidUpdate = (newBid) => {
        setChangeBid(false);
        handleChangeBid(newBid, row?.name);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedChangeHandler = useCallback(debounce(bid => handleBidUpdate(bid), 1000), []);

    const getRateModel = (rateModel) => {
        if (rateModel !== "" && rateModel !== undefined && rateModel !== null) {
            return rateModel.toUpperCase();
        }
        return "CPC";
    };

    useEffect(() => {
        if (bid !== undefined && bid !== null && bid !== "" && changeBid) {
            debouncedChangeHandler(bid);
        }
    }, [bid, debouncedChangeHandler, changeBid]);

    useEffect(() => {
        if (row?.bid !== undefined && row?.bid !== null) {
            setBid(row?.bid)
        }
    }, [row?.bid])

    if (!row.hasOwnProperty("bid")) {
        return "";
    }

    return (
        <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="noWrap"
             sx={{paddingTop: "3px", marginLeft: "-8px"}}
        >
            <Box>
                <FormGroup row={true}>
                    <TextField
                        type="text"
                        name="bid"
                        value={bid}
                        onChange={handleInputChange}
                        onBlur={() => formatInput()}
                        InputProps={{
                            sx: {
                                position: "relative",
                                height: "28px",
                                minHeight: "28px",
                                maxHeight: "28px",
                                fontSize: "14px",
                                width: "130px",
                                textAlign: "center",
                                border: "0",
                                boxShadow: "none",
                                backgroundColor: "background.darkBlue",
                                color: "common.white",
                                borderBottomLeftRadius: "4px",
                                borderTopLeftRadius: "4px",
                                borderBottomRightRadius: 0,
                                borderTopRightRadius: 0,
                                "& .MuiOutlinedInput-input": {
                                    backgroundColor: "background.darkBlue",
                                    padding: 0,
                                    fontSize: "14px",
                                    color: "common.white",
                                }
                            },
                            disableUnderline: true,
                            startAdornment: <InputAdornment position="start">
                                <Box display="flex" flexDirection="row" flexWrap="noWrap">
                                    <Box sx={{
                                        paddingTop: 0,
                                        paddingLeft: 0,
                                        color: "text.disabledLight",
                                        fontSize: "14px",
                                    }}>
                                        {getRateModel(row?.rateModel)}
                                    </Box>
                                    <Box sx={{color: "text.disabledLight"}} pl="14px">$</Box>
                                </Box>
                            </InputAdornment>
                        }}
                    />
                    <Button
                        type="button"
                        variant="contained"
                        onClick={handleDecrement}
                        sx={{
                            color: "text.primary",
                            backgroundColor: "background.darkBlue",
                            border: "0px",
                            boxShadow: "none",
                            "&:hover": {
                                backgroundColor: "background.darkBlue",
                                color: "error.main",
                                boxShadow: "none"
                            },
                            borderRadius: "0px",
                            position: "relative",
                            height: "100%",
                            minHeight: "28px",
                            maxHeight: "28px",
                            minWidth: "20px",
                            maxWidth: "20px",
                            padding: "4px 16px",
                        }}
                    >
                        <Remove fontSize="small"/>
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        onClick={handleIncrement}
                        sx={{
                            color: "text.primary",
                            backgroundColor: "background.darkBlue",
                            border: "0px",
                            boxShadow: "none",
                            "&:hover": {
                                backgroundColor: "background.darkBlue",
                                color: "primary.main",
                                boxShadow: "none"
                            },
                            position: "relative",
                            height: "100%",
                            minHeight: "28px",
                            maxHeight: "28px",
                            minWidth: "20px",
                            maxWidth: "20px",
                            borderBottomLeftRadius: "0px",
                            borderTopLeftRadius: "0px"
                        }}
                    >
                        <Add fontSize="small"/>
                    </Button>
                </FormGroup>
            </Box>
        </Box>
    );
}