import {columnConstants} from "../constants/columnConstants";

const initialState = {
    data: null,
    rerender: false
};

const columnReducer = (state = initialState, action) => {
    switch (action.type) {
        case columnConstants.COLUMN_FETCH_DATA:
            return {
                ...state,
                data: action?.result,
                rerender: false
            };
        case columnConstants.COLUMN_UPDATE_COLUMN_WIDTH:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action?.payload?.columnGroup]: state.data[action?.payload?.columnGroup]?.map((column) => {
                        if (column?.id === action?.payload?.columnID) {
                            column.width = parseInt(action?.payload?.width);
                        }
                        return column
                    })
                }
            };
        case columnConstants.COLUMN_UPDATE_COLUMN_VISIBILITY:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action?.payload?.columnGroup]: state.data[action?.payload?.columnGroup]?.map((column) => {
                        if (column?.id === action?.payload?.columnID) {
                            column.hidden = action?.payload?.hidden;
                        }
                        return column
                    })
                }
            };
        case columnConstants.COLUMN_UPDATE_COLUMN_SEQUENCE:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action?.payload?.columnGroup]: state.data[action?.payload?.columnGroup]?.map((column) => {
                        if (column?.id === action?.payload?.sourceColumnID) {
                            column.sequence = action?.payload?.targetColumnSequence;
                        }
                        if (column?.id === action?.payload?.targetColumnID) {
                            column.sequence = action?.payload?.sourceColumnSequence;
                        }
                        return column
                    })
                }
            };
        case columnConstants.COLUMN_RERENDER:
            return {
                ...state,
                rerender: true
            };
        default:
            return state;
    }
};

export default columnReducer;