import {reportServices} from "../../service/reportService";
import {groupBy1Constants} from "../constants/groupBy1Constants";
import {alertActions} from "./alertActions";
import {campaignServices} from "../../service/campaignService";
import {trafficSourceServices} from "../../service/trafficSourceService";
import {integrationService} from "../../service/integrationService";
import {
    allowToGenerateHash, generateMD5HashHex, GetSelectedGroupByPlaceholderFromTrafficSource,
} from "../../components/reporting-table/ReactDataGridUtils";
import ExportCSV from "../../components/export/ExportCSV";
import {v4 as uuidv4} from 'uuid';

export const groupBy1Action = {
    getDetailsReport,
    setSelectedName,
    updateOrderBy,
    updateSortBy,
    updateSortColumns,
    rerender,
    setSelectedRows,
    updateGroupBy,
    updateStatus,
    updateBid,
    getIntegrationData,
    resetIntegration,
    resetTrafficSource,
    reset,
};

function getDetailsReport(params, infinityScroll = false) {
    return (dispatch, getState) => {
        dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
        dispatch(alertActions.error(""));

        let integration = getState().groupBy1.integration;
        let trafficSource = getState().groupBy1.trafficSource;
        let column = getState().column.data;
        let groupBy = getState().groupBy1.groupBy?.groupBy;
        let groupByPlaceholder = GetSelectedGroupByPlaceholderFromTrafficSource(groupBy, trafficSource);

        // get details report
        reportServices.getDetailsReport(params)
            .then(async (result) => {

                // CSV export
                if (params?.export === "csv") {
                    ExportCSV({fileName: "details_report_" + params?.from + "_to_" + params?.to, data: result});
                    dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    return false;
                }

                // Add hashed custom1-10 values if column hash is not hidden.
                if (allowToGenerateHash(groupBy, column?.detailsReport)) {
                    for (const item of result?.records) {
                        item.hash = generateMD5HashHex(item?.name);
                    }
                }

                // Add UUID
                for (const item of result?.records) {
                    item.uuid = uuidv4();
                }

                // Add Pushground integration check.
                if (integration?.networkType === "pushground") {
                    for (const item of result?.records) {
                        let pushgroundId = item?.name;
                        if (pushgroundId !== "" && pushgroundId.match(/^\d+$/) !== null) {
                            // find Pushground campaign if group by placeholder is {campaign_id}
                            if (groupByPlaceholder === "{campaign_id}") {
                                let pResult = await integrationService.findPushgroundCampaign(integration?.id, pushgroundId);
                                if (pResult?.id > 0) {
                                    item.status = pResult?.active;
                                    item.bid = pResult?.bid;
                                }
                            }
                        }
                    }
                }

                // Add PropellerAds integration check.
                if (integration?.networkType === "propeller-ads") {
                    let campaignIds = "";
                    for (const item of result?.records) {
                        if (item?.name?.match(/^\d+$/) !== null) {
                            if (campaignIds === "") {
                                campaignIds += item?.name;
                            } else {
                                campaignIds += "," + item?.name;
                            }
                        }
                    }

                    // find PropellerAds campaigns if group by placeholder is {campaignid}
                    if (campaignIds !== "" && groupByPlaceholder === "{campaignid}") {
                        let pResult = await integrationService.findPropellerAdsCampaigns({
                            integrationId: integration?.id, campaignIds: campaignIds
                        });

                        for (const item of result?.records) {
                            for (const pCampaign of pResult) {
                                if (parseInt(pCampaign?.id) === parseInt(item?.name)) {
                                    item.status = pCampaign?.active;
                                    item.bid = pCampaign?.bid;
                                    item.rateModel = pCampaign?.rateModel;
                                }
                            }
                        }
                    }
                }

                // Add ExoClick integration check.
                if (integration?.networkType === "exoclick") {
                    let campaignIds = "";
                    for (const item of result?.records) {
                        if (item?.name?.match(/^\d+$/) !== null) {
                            if (campaignIds === "") {
                                campaignIds += item?.name;
                            } else {
                                campaignIds += "," + item?.name;
                            }
                        }
                    }

                    // find ExoClick campaigns if group by placeholder is {campaign_id}
                    if (campaignIds !== "" && groupByPlaceholder === "{campaign_id}") {
                        let pResult = await integrationService.findExoClickCampaigns({
                            integrationId: integration?.id, campaignIds: campaignIds
                        });

                        for (const item of result?.records) {
                            for (const pCampaign of pResult) {
                                if (parseInt(pCampaign?.id) === parseInt(item?.name)) {
                                    item.status = pCampaign?.active;
                                    item.bid = pCampaign?.bid;
                                    item.rateModel = pCampaign?.rateModel;
                                }
                            }
                        }
                    }
                }

                // Add Mondiad integration check.
                if (integration?.networkType === "mondiad") {
                    let ids = "";
                    for (const item of result?.records) {
                        if (item?.name?.match(/^\d+$/) !== null) {
                            if (ids === "") {
                                ids += item?.name;
                            } else {
                                ids += "," + item?.name;
                            }
                        }
                    }

                    // find Mondiad campaigns if group by placeholder is [campaignid]
                    if (ids !== "" && groupByPlaceholder === "[campaignid]") {
                        try {
                            let pResult = await integrationService.findMondiadCampaigns({
                                integrationId: integration?.id, campaignIds: ids
                            });
                            for (const item of result?.records) {
                                for (const pCampaign of pResult) {
                                    if (parseInt(pCampaign?.id) === parseInt(item?.name)) {
                                        item.status = pCampaign?.active;
                                        item.bid = pCampaign?.bid;
                                        item.rateModel = pCampaign?.rateModel;
                                        break;
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }
                    // find Mondiad creatives if group by placeholder is [creativeid]
                    if (ids !== "" && groupByPlaceholder === "[creativeid]") {
                        try {
                            let intResult = await integrationService.findMondiadCreatives(
                                {integrationId: integration?.id, ids: ids}
                            );
                            for (const item of result?.records) {
                                for (const creative of intResult?.creatives) {
                                    if (parseInt(creative?.id) === parseInt(item?.name)) {
                                        item.status = creative?.active;
                                        break;
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }
                }

                // Add Google Ads integration check.
                if (integration?.networkType === "google") {
                    let ids = "";
                    for (const item of result?.records) {
                        if (item?.name?.match(/^\d+$/) !== null) {
                            if (ids === "") {
                                ids += item?.name;
                            } else {
                                ids += "," + item?.name;
                            }
                        }
                    }

                    // find Google Ads campaigns if group by placeholder is {campaignid}
                    if (ids !== "" && groupByPlaceholder === "{campaignid}") {
                        try {
                            let iResult = await integrationService.findGoogleAdsCampaigns({
                                integrationId: integration?.id, campaignIds: ids
                            });
                            for (const item of result?.records) {
                                for (const i of iResult) {
                                    if (parseInt(i?.id) === parseInt(item?.name)) {
                                        item.trafficSourceName = i?.name;
                                        if (i?.status === "ENABLED" || i?.status === "PAUSED") {
                                            item.status = i?.active;
                                        }
                                        break;
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }

                    // find Google Ads ad groups if group by placeholder is {adgroupid}
                    if (ids !== "" && groupByPlaceholder === "{adgroupid}") {
                        try {
                            let iResult = await integrationService.findGoogleAdsAdGroups({
                                integrationId: integration?.id, adGroupIds: ids
                            });
                            for (const item of result?.records) {
                                for (const i of iResult) {
                                    if (parseInt(i?.id) === parseInt(item?.name)) {
                                        item.trafficSourceName = i?.name;
                                        if (i?.status === "ENABLED" || i?.status === "PAUSED") {
                                            item.status = i?.active;
                                        }
                                        break;
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }

                    // find Google ads if group by placeholder is {creative}
                    if (ids !== "" && groupByPlaceholder === "{creative}") {
                        try {
                            let iResult = await integrationService.findGoogleAdsAdGroupAds({
                                integrationId: integration?.id, adIds: ids
                            });
                            for (const item of result?.records) {
                                for (const i of iResult) {
                                    if (parseInt(i?.id) === parseInt(item?.name)) {
                                        item.trafficSourceName = i?.name;
                                        item.trafficSourceAdGroupId = i?.adGroupId;
                                        if (i?.status === "ENABLED" || i?.status === "PAUSED") {
                                            item.status = i?.active;
                                        }
                                        break;
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }
                }

                // Add Microsoft Ads integration check.
                if (integration?.networkType === "microsoft") {
                    let ids = "";
                    for (const item of result?.records) {
                        if (item?.name?.match(/^\d+$/) !== null) {
                            if (ids === "") {
                                ids += item?.name;
                            } else {
                                ids += "," + item?.name;
                            }
                        }
                    }

                    // find Microsoft Ads campaigns if group by placeholder is {CampaignId}
                    if (ids !== "" && groupByPlaceholder === "{CampaignId}") {
                        try {
                            let iResult = await integrationService.findMicrosoftAdsCampaigns({
                                integrationId: integration?.id, campaignIds: ids
                            });
                            for (const item of result?.records) {
                                for (const i of iResult) {
                                    if (parseInt(i?.id) === parseInt(item?.name)) {
                                        item.trafficSourceName = i?.name;
                                        if (i?.status === "Active" || i?.status === "Paused") {
                                            item.status = i?.active;
                                        }
                                        break;
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }
                }

                // Add Facebook Ads integration check.
                if (integration?.networkType === "facebook") {
                    let ids = "";
                    for (const item of result?.records) {
                        if (item?.name?.match(/^\d+$/) !== null) {
                            if (ids === "") {
                                ids += item?.name;
                            } else {
                                ids += "," + item?.name;
                            }
                        }
                    }

                    // find Facebook Ads campaign, ad set and ad titles.
                    if (ids !== "" && ["{{campaign.id}}", "{{adset.id}}", "{{ad.id}}"].indexOf(groupByPlaceholder) !== -1) {

                        let level = "campaign";
                        if (groupByPlaceholder === "{{adset.id}}") {
                            level = "adset";
                        }
                        if (groupByPlaceholder === "{{ad.id}}") {
                            level = "ad";
                        }

                        try {
                            let iResult = await integrationService.findFacebookMetricsAndOtherFields({
                                integrationId: integration?.id,
                                level: level,
                                ids: ids,
                                dateStart: params?.from,
                                dateStop: params?.to
                            });
                            for (const item of result?.records) {
                                if (iResult?.insights?.length > 0) {
                                    for (const i of iResult?.insights) {
                                        if (i?.campaignId === item?.name) {
                                            item.trafficSourceName = i?.campaignName;
                                            item.fbCpm = i?.cpm;
                                            item.fbImpressions = i?.impressions;
                                            item.fbLinkClicks = i?.linkClicks;
                                            item.fbLinkCpc = i?.linkCpc;
                                            item.fbCtr = i?.linkCtr;
                                            item.fbAccountCurrency = i?.accountCurrency;
                                            item.fbEpc = parseFloat(item?.revenue) / parseInt(i?.linkClicks);
                                            break;
                                        } else if (i?.adSetId === item?.name) {
                                            item.trafficSourceName = i?.adSetName;
                                            item.fbCpm = i?.cpm;
                                            item.fbImpressions = i?.impressions;
                                            item.fbLinkClicks = i?.linkClicks;
                                            item.fbLinkCpc = i?.linkCpc;
                                            item.fbCtr = i?.linkCtr;
                                            item.fbAccountCurrency = i?.accountCurrency;
                                            item.fbEpc = parseFloat(item?.revenue) / parseInt(i?.linkClicks);
                                            break;
                                        } else if (i?.adId === item?.name) {
                                            item.trafficSourceName = i?.adName;
                                            item.fbCpm = i?.cpm;
                                            item.fbImpressions = i?.impressions;
                                            item.fbLinkClicks = i?.linkClicks;
                                            item.fbLinkCpc = i?.linkCpc;
                                            item.fbCtr = i?.linkCtr;
                                            item.fbAccountCurrency = i?.accountCurrency;
                                            item.fbEpc = parseFloat(item?.revenue) / parseInt(i?.linkClicks);
                                            break;
                                        }
                                    }
                                }
                                if (iResult?.campaigns?.length > 0) {
                                    for (const c of iResult?.campaigns) {
                                        if (c?.id === item?.name) {
                                            item.trafficSourceName = c?.name;
                                            item.status = c?.status;
                                            item.fbDailyBudget = c?.dailyBudget;
                                            item.deliveryStatus = c?.deliveryStatus;
                                            break;
                                        }
                                    }
                                }
                                if (iResult?.adSets?.length > 0) {
                                    for (const adSet of iResult?.adSets) {
                                        if (adSet?.id === item?.name) {
                                            item.trafficSourceName = adSet?.name;
                                            item.status = adSet?.status;
                                            item.fbDailyBudget = adSet?.dailyBudget;
                                            item.deliveryStatus = adSet?.deliveryStatus;
                                            break;
                                        }
                                    }
                                }
                                if (iResult?.ads?.length > 0) {
                                    for (const ad of iResult?.ads) {
                                        if (ad?.id === item?.name) {
                                            item.trafficSourceName = ad?.name;
                                            item.status = ad?.status;
                                            item.fbDailyBudget = ad?.dailyBudget;
                                            item.deliveryStatus = ad?.deliveryStatus;
                                            break;
                                        }
                                    }
                                }
                            }
                        } catch (e) {
                            if (e?.response?.data?.error) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            } else {
                                dispatch(alertActions.error("Unable to load Facebook metrics data."));
                            }
                        }
                    }
                }

                // Add Taboola Ads integration check.
                if (integration?.networkType === "taboola") {
                    // find Taboola campaigns if group by placeholder is {campaign_id}
                    if (groupByPlaceholder === "{campaign_id}") {
                        try {
                            let iResult = await integrationService.findTaboolaCampaigns({
                                integrationId: integration?.id
                            });
                            for (const item of result?.records) {
                                for (const i of iResult) {
                                    if (parseInt(i?.id) === parseInt(item?.name)) {
                                        item.trafficSourceName = i?.name;
                                        if (i?.status === "RUNNING" || i?.status === "PAUSED" || i?.status === "PENDING_APPROVAL") {
                                            item.status = i?.active;
                                        }
                                        break;
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }
                }

                // Add MGID Ads integration check.
                if (integration?.networkType === "mgid") {
                    // find MGID campaigns if group by placeholder is {campaign_id}
                    if (groupByPlaceholder === "{campaign_id}") {
                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^\d+$/) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        if (ids !== "") {
                            try {
                                let iResult = await integrationService.findMgidCampaigns({
                                    integrationId: integration?.id,
                                    campaignIds: ids
                                });
                                for (const item of result?.records) {
                                    for (const i of iResult) {
                                        if (parseInt(i?.id) === parseInt(item?.name)) {
                                            item.trafficSourceName = i?.name;
                                            item.status = i?.active;
                                            item.deliveryStatus = i?.deliveryStatus;
                                            break;
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }
                    }
                    if (groupByPlaceholder === "{teaser_id}") {
                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^\d+$/) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        if (ids !== "") {
                            try {
                                let iResult = await integrationService.findMgidAds({
                                    integrationId: integration?.id,
                                    adIds: ids
                                });
                                for (const item of result?.records) {
                                    for (const i of iResult) {
                                        if (parseInt(i?.id) === parseInt(item?.name)) {
                                            item.status = i?.active;
                                            item.deliveryStatus = i?.deliveryStatus
                                            let bidNum = i?.bid / 100;
                                            item.bid = bidNum.toFixed(4);
                                            break;
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }
                    }
                }

                // Add Outbrain Ads integration check.
                if (integration?.networkType === "outbrain") {
                    // find Outbrain campaigns if group by placeholder is {{campaign_id}}
                    if (groupByPlaceholder === "{{campaign_id}}") {
                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^[a-z0-9]{34}$/) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        if (ids !== "") {
                            try {
                                let iResult = await integrationService.findOutbrainCampaigns({
                                    integrationId: integration?.id,
                                    campaignIds: ids
                                });
                                for (const item of result?.records) {
                                    for (const i of iResult) {
                                        if (i?.id === item?.name) {
                                            item.trafficSourceName = i?.name;
                                            item.status = i?.status;
                                            item.deliveryStatus = i?.deliveryStatus;
                                            item.bid = i?.bid;
                                            item.bidRate = i?.bidRate;
                                            break;
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }
                    }
                }

                // Add Facebook Ads integration check.
                if (integration?.networkType === "tiktok") {
                    let ids = "";
                    for (const item of result?.records) {
                        if (item?.name?.match(/^\d+$/) !== null) {
                            if (ids === "") {
                                ids += item?.name;
                            } else {
                                ids += "," + item?.name;
                            }
                        }
                    }

                    // Find TikTok campaign, adgroup and ad titles.
                    if (ids !== "" && ["__CAMPAIGN_ID__", "__AID__", "__CID__"].indexOf(groupByPlaceholder) !== -1) {

                        let level = "campaign";
                        if (groupByPlaceholder === "__AID__") {
                            level = "adgroup";
                        } else if (groupByPlaceholder === "__CID__") {
                            level = "ad";
                        }

                        try {
                            let iResult = await integrationService.findTikTokMetricsAndOtherFields({
                                integrationId: integration?.id,
                                level: level,
                                ids: ids,
                                dateStart: params?.from,
                                dateStop: params?.to
                            });
                            for (const item of result?.records) {
                                if (iResult?.insights?.length > 0) {
                                    for (const i of iResult?.insights) {
                                        if (parseInt(i?.campaignId) === parseInt(item?.name)) {
                                            item.trafficSourceName = i?.campaignName;
                                            item.fbCpm = i?.cpm;
                                            item.fbImpressions = i?.impressions;
                                            item.fbLinkClicks = i?.linkClicks;
                                            item.fbLinkCpc = i?.linkCpc;
                                            item.fbCtr = i?.linkCtr;
                                            item.fbAccountCurrency = i?.accountCurrency;
                                            item.fbEpc = parseFloat(item?.revenue) / parseInt(i?.linkClicks);
                                            break;
                                        } else if (parseInt(i?.adGroupId) === parseInt(item?.name)) {
                                            item.trafficSourceName = i?.adGroupName;
                                            item.fbCpm = i?.cpm;
                                            item.fbImpressions = i?.impressions;
                                            item.fbLinkClicks = i?.linkClicks;
                                            item.fbLinkCpc = i?.linkCpc;
                                            item.fbCtr = i?.linkCtr;
                                            item.fbAccountCurrency = i?.accountCurrency;
                                            item.fbEpc = parseFloat(item?.revenue) / parseInt(i?.linkClicks);
                                            break;
                                        } else if (parseInt(i?.adId) === parseInt(item?.name)) {
                                            item.trafficSourceName = i?.adName;
                                            item.fbCpm = i?.cpm;
                                            item.fbImpressions = i?.impressions;
                                            item.fbLinkClicks = i?.linkClicks;
                                            item.fbLinkCpc = i?.linkCpc;
                                            item.fbCtr = i?.linkCtr;
                                            item.fbAccountCurrency = i?.accountCurrency;
                                            item.fbEpc = parseFloat(item?.revenue) / parseInt(i?.linkClicks);
                                            break;
                                        }
                                    }
                                }
                                if (iResult?.campaigns?.length > 0) {
                                    for (const c of iResult?.campaigns) {
                                        if (parseInt(c?.id) === parseInt(item?.name)) {
                                            item.trafficSourceName = c?.name;
                                            item.status = c?.status;
                                            if (c?.dailyBudget > 0) {
                                                item.fbDailyBudget = c?.dailyBudget;
                                            }
                                            item.deliveryStatus = c?.deliveryStatus;
                                            break;
                                        }
                                    }
                                }
                                if (iResult?.adGroups?.length > 0) {
                                    for (const adGroup of iResult?.adGroups) {
                                        if (parseInt(adGroup?.id) === parseInt(item?.name)) {
                                            item.trafficSourceName = adGroup?.name;
                                            item.status = adGroup?.status;
                                            if (adGroup?.dailyBudget > 0) {
                                                item.fbDailyBudget = adGroup?.dailyBudget;
                                            }
                                            item.deliveryStatus = adGroup?.deliveryStatus;
                                            break;
                                        }
                                    }
                                }
                                if (iResult?.ads?.length > 0) {
                                    for (const ad of iResult?.ads) {
                                        if (parseInt(ad?.id) === parseInt(item?.name)) {
                                            item.trafficSourceName = ad?.name;
                                            item.status = ad?.status;
                                            item.fbDailyBudget = ad?.dailyBudget;
                                            item.deliveryStatus = ad?.deliveryStatus;
                                            break;
                                        }
                                    }
                                }
                            }
                        } catch (e) {
                            if (e?.response?.data?.error) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            } else {
                                dispatch(alertActions.error("Unable to load TikTok metrics data."));
                            }
                        }
                    }
                }

                if (infinityScroll) {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_INFINITY_SCROLL, result});
                } else {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                }
            }).catch((error) => {
                dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                if (error?.response?.data?.error) {
                    dispatch(alertActions.error(error?.response?.data?.error));
                } else {
                    dispatch(alertActions.error("Invalid request. Please change your Group By values."));
                }
            }
        );
    };
}

function setSelectedName(name) {
    return (dispatch) => {
        dispatch({type: groupBy1Constants.GROUP_BY_1_SET_SELECTED_NAME, name});
    };
}

function updateOrderBy(orderBy) {
    return (dispatch) => {
        dispatch({type: groupBy1Constants.GROUP_BY_1_UPDATE_ORDER_BY, orderBy});
    };
}

function updateSortBy(sortBy) {
    return (dispatch) => {
        dispatch({type: groupBy1Constants.GROUP_BY_1_UPDATE_SORT_BY, sortBy});
    };
}

function updateSortColumns(sortColumns) {
    return (dispatch) => {
        dispatch({type: groupBy1Constants.GROUP_BY_1_UPDATE_SORT_COLUMNS, sortColumns});
    };
}

function rerender() {
    return dispatch => {
        dispatch({type: groupBy1Constants.GROUP_BY_1_RERENDER});
    };
}

function setSelectedRows(selectedUUIDs, selectedRows) {
    return dispatch => {
        dispatch({type: groupBy1Constants.GROUP_BY_1_SET_SELECTED_ROW, payload: {selectedUUIDs, selectedRows}});
    };
}

function updateGroupBy(groupBy) {
    return dispatch => {
        dispatch({type: groupBy1Constants.GROUP_BY_1_UPDATE_GROUP_BY, groupBy});
    };
}

function updateStatus(status, rowId) {
    return (dispatch, getState) => {

        let result = getState().groupBy1.data;
        let integration = getState().groupBy1.integration;
        let trafficSource = getState().groupBy1.trafficSource;
        let groupBy = getState().groupBy1.groupBy?.groupBy;
        let groupByPlaceholder = GetSelectedGroupByPlaceholderFromTrafficSource(groupBy, trafficSource);

        if (integration !== null) {
            if (integration?.networkType === "pushground") {
                if (groupByPlaceholder === "{campaign_id}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updatePushgroundCampaignStatus(status, integration?.id, rowId).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.uuid = uuidv4();
                                i.status = status;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "propeller-ads") {
                if (groupByPlaceholder === "{campaignid}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updatePropellerAdsCampaignStatus({
                        status: status, integrationId: integration?.id, campaignId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.uuid = uuidv4();
                                i.status = status;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "exoclick") {
                if (groupByPlaceholder === "{campaign_id}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateExoClickCampaignStatus({
                        status: status, integrationId: integration?.id, campaignId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.uuid = uuidv4();
                                i.status = status;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "mondiad") {
                if (groupByPlaceholder === "[campaignid]") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateMondiadCampaignStatus({
                        status: status, integrationId: integration?.id, campaignId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.status = status;
                                i.uuid = uuidv4();
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
                if (groupByPlaceholder === "[creativeid]") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateMondiadCreativeStatus({
                        status: status, integrationId: integration?.id, creativeId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.status = status;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "google") {
                if (groupByPlaceholder === "{campaignid}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateGoogleAdsCampaignStatus({
                        status: status, integrationId: integration?.id, campaignId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.uuid = uuidv4();
                                i.status = status;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
                if (groupByPlaceholder === "{adgroupid}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateGoogleAdsAdGroupStatus({
                        status: status, integrationId: integration?.id, adGroupId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.status = status;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
                if (groupByPlaceholder === "{creative}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));

                    let adGroupId = 0;
                    for (let i of result?.records) {
                        if (i?.trafficSourceAdGroupId > 0) {
                            adGroupId = i?.trafficSourceAdGroupId;
                            break;
                        }
                    }

                    integrationService.updateGoogleAdsAdGroupAdStatus({
                        status: status, integrationId: integration?.id, adGroupId, adId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.status = status;
                                break;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "microsoft") {
                if (groupByPlaceholder === "{CampaignId}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateMicrosoftAdsCampaignStatus({
                        status: status, integrationId: integration?.id, campaignId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.uuid = uuidv4();
                                i.status = status;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "taboola") {
                if (groupByPlaceholder === "{campaign_id}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateTaboolaCampaignStatus({
                        status: status, integrationId: integration?.id, campaignId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.uuid = uuidv4();
                                i.status = status;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "facebook") {
                let fbPlaceholders = ["{{campaign.id}}", "{{adset.id}}", "{{ad.id}}"]
                if (fbPlaceholders.indexOf(groupByPlaceholder) !== -1) {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateFacebookStatus({
                        status: status, integrationId: integration?.id, id: rowId
                    }).then((response) => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.status = status;
                                i.uuid = uuidv4();
                                if (response?.success === true && status) {
                                    i.deliveryStatus = "Active"
                                } else {
                                    i.deliveryStatus = "Paused"
                                }
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "mgid") {
                if (groupByPlaceholder === "{campaign_id}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateMgidCampaignStatus({
                        status: status, integrationId: integration?.id, campaignId: rowId
                    }).then((response) => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.status = status;
                                i.uuid = uuidv4();
                                i.deliveryStatus = response?.deliveryStatus;
                                break;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
                if (groupByPlaceholder === "{teaser_id}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateMgidAdStatus({
                        status: status, integrationId: integration?.id, adId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.status = status;
                                break;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "outbrain") {
                if (groupByPlaceholder === "{{campaign_id}}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateOutbrainCampaignStatus({
                        status: status, integrationId: integration?.id, campaignId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.uuid = uuidv4();
                                i.status = status;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "tiktok") {
                let fbPlaceholders = ["__CAMPAIGN_ID__", "__AID__", "__CID__"]

                let level = "campaign";
                if (groupByPlaceholder === "__AID__") {
                    level = "adgroup";
                } else if (groupByPlaceholder === "__CID__") {
                    level = "ad"
                }

                if (fbPlaceholders.indexOf(groupByPlaceholder) !== -1) {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateTikTokStatus({
                        status: status, level: level, integrationId: integration?.id, id: rowId
                    }).then((response) => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.status = status;
                                i.uuid = uuidv4();
                                if (response?.success === true && status) {
                                    i.deliveryStatus = "Active"
                                } else {
                                    i.deliveryStatus = "Inactive"
                                }
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }
        }
    };
}

function updateBid(bid, rowId) {
    return (dispatch, getState) => {

        let result = getState().groupBy1.data;
        let integration = getState().groupBy1.integration;
        let trafficSource = getState().groupBy1.trafficSource;
        let groupBy = getState().groupBy1.groupBy?.groupBy;
        let groupByPlaceholder = GetSelectedGroupByPlaceholderFromTrafficSource(groupBy, trafficSource);

        if (integration !== null) {
            if (integration?.networkType === "pushground") {
                if (groupByPlaceholder === "{campaign_id}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updatePushgroundCampaignBid(bid, integration?.id, rowId).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.uuid = uuidv4();
                                i.bid = bid;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "propeller-ads") {
                if (groupByPlaceholder === "{campaignid}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updatePropellerAdsCampaignBid({
                        bid: bid, integrationId: integration?.id, campaignId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.uuid = uuidv4();
                                i.bid = bid;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "exoclick") {
                if (groupByPlaceholder === "{campaign_id}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateExoClickCampaignBid({
                        bid: bid, integrationId: integration?.id, campaignId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.uuid = uuidv4();
                                i.bid = bid;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "mondiad") {
                if (groupByPlaceholder === "[campaignid]") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateMondiadCampaignBid({
                        bid: bid, integrationId: integration?.id, campaignId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.uuid = uuidv4();
                                i.bid = bid;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "facebook") {
                if (bid > 0 && ["{{campaign.id}}", "{{adset.id}}", "{{ad.id}}"].indexOf(groupByPlaceholder) !== -1) {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateFacebookDailyBudget({
                        dailyBudget: bid, integrationId: integration?.id, id: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.uuid = uuidv4();
                                i.fbDailyBudget = parseFloat(bid);
                                break;
                            }
                        }
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                        dispatch(alertActions.success("Updated successfully"));
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "mgid") {
                if (groupByPlaceholder === "{teaser_id}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateMgidAdBid({
                        bid: bid, integrationId: integration?.id, adId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.uuid = uuidv4();
                                i.bid = bid;
                                break;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "outbrain") {
                if (groupByPlaceholder === "{{campaign_id}}") {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateOutbrainCampaignBid({
                        bid: bid, integrationId: integration?.id, campaignId: rowId
                    }).then(() => {
                        for (let i of result?.records) {
                            if (i?.name === rowId) {
                                i.uuid = uuidv4();
                                i.bid = bid;
                                break;
                            }
                        }
                        // Set modified result.
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }

            if (integration?.networkType === "tiktok") {
                let ttPlaceholders = ["__CAMPAIGN_ID__", "__AID__", "__CID__"]

                let level = "campaign";
                if (groupByPlaceholder === "__AID__") {
                    level = "adgroup";
                }

                if (bid > 0 && ttPlaceholders.indexOf(groupByPlaceholder) !== -1) {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
                    dispatch(alertActions.error(""));
                    integrationService.updateTikTokDailyBudget({
                        dailyBudget: bid, level: level, integrationId: integration?.id, id: rowId
                    }).then((response) => {
                        if (!response?.success) {
                            dispatch(alertActions.error(response?.message));
                            dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                        } else {
                            for (let i of result?.records) {
                                if (i?.name === rowId) {
                                    i.fbDailyBudget = bid;
                                    i.uuid = uuidv4();
                                    break;
                                }
                            }
                            dispatch({type: groupBy1Constants.GROUP_BY_1_GET_DATA, result});
                            dispatch(alertActions.success("Updated successfully"));
                        }
                    }).catch((e) => {
                        dispatch(alertActions.error(e?.response?.data?.error));
                    }).finally(() => {
                        dispatch({type: groupBy1Constants.GROUP_BY_1_STOP_LOADING_SPINNER});
                    });
                }
            }
        }
    };
}

function getIntegrationData(campaignId) {
    return dispatch => {
        dispatch({type: groupBy1Constants.GROUP_BY_1_LOADING_SPINNER});
        dispatch(alertActions.error(""));
        // find campaign by campaign ID.
        campaignServices.find(campaignId).then((campaign) => {
            // find traffic source by trafficID with integration info.
            trafficSourceServices.find(campaign?.trafficId).then((trafficSource) => {
                dispatch({type: groupBy1Constants.GROUP_BY_1_SET_TRAFFIC_SOURCE_DATA, trafficSource});
                // find integration
                integrationService.findIntegration(trafficSource?.integrationId).then((integration) => {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_SET_INTEGRATION_DATA, integration});
                }).catch((e) => {
                    dispatch({type: groupBy1Constants.GROUP_BY_1_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                });
            }).catch((e) => {
                dispatch({type: groupBy1Constants.GROUP_BY_1_ERROR, e});
                dispatch(alertActions.error(e?.response?.data?.error));
            });
        }).catch((e) => {
            dispatch({type: groupBy1Constants.GROUP_BY_1_ERROR, e});
            dispatch(alertActions.error(e?.response?.data?.error));
        });
    };
}

function resetIntegration() {
    return dispatch => {
        dispatch({type: groupBy1Constants.GROUP_BY_1_SET_INTEGRATION_DATA, integration: null});
    };
}

function resetTrafficSource() {
    return dispatch => {
        dispatch({type: groupBy1Constants.GROUP_BY_1_SET_TRAFFIC_SOURCE_DATA, trafficSource: null});
    };
}

function reset() {
    return dispatch => {
        dispatch({type: groupBy1Constants.GROUP_BY_1_RESET});
    };
}