import React, {createRef, useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from "react-redux";
import {compose} from "redux";
import CustomSnackbar from "../alert/CustomSnackbar";
import DataGrid, {SelectColumn} from "react-data-grid";
import {
    CustomTableHeaderCellRender,
    EmptyRowsRenderer,
    GetSelectedRowID,
    IsAtBottom
} from "../reporting-table/ReactDataGridUtils";
import {debounce} from "lodash";
import Spinner from "../spinner/Spinner";
import CustomToolbar from "../utils/CustomToolbar";
import {DynamicGridColumnCreator, MarkColumn} from "../reporting-table/Columns";
import {campaignActions} from "../../store/actions/campaignsAction";
import {GetLocalStorageCache, SetLocalStorageCache} from "../cache/Cache";
import {groupBy1Action} from "../../store/actions/groupBy1Action";
import {groupBy2Action} from "../../store/actions/groupBy2Action";
import {groupBy3Action} from "../../store/actions/groupBy3Action";
import {columnActions} from "../../store/actions/columnAction";
import {workspaceActions} from "../../store/actions/workspaceAction";
import {GetCommaSeparatedList} from "../../helpers/common";
import {Divider, useTheme} from "@mui/material";
import useCustomStyles from "../utils/UseCustomStyles";
import {CustomCampaignDataGridBox} from "./CampaignsView";

const customStyles = (theme) => ({
    dataGrid: theme.defaultDataGrid
});

function CampaignsGrid(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const {
        alert, getReport, updateSortBy, updateOrderBy, updateSortColumns, loadMoreRows, setSelectedRows,
        groupBy, columnGroup, report, getWorkspaceIDs
    } = props;
    const {data, isLoading, name, tags, orderBy, sortBy, sortColumns, rerender, selectedRows} = props.campaignsState;
    const {from, to, isDeleted} = props.ddToolbarState;
    const {timezone, currencyCode} = props.auth.userSettings;

    const gridRef = createRef(null);
    const [columns, setColumns] = useState(null);

    const updateColumnResize = (idx, width) => {
        props.updateColumnWidth({columnKey: columns[idx]?.key, width, columnGroup, report});
    };

    const modifiedTableColumns = useMemo(() => {
        function CustomHeaderCell(props) {
            return <CustomTableHeaderCellRender {...props}/>;
        }

        return columns?.map((c) => {
            if (c?.key === "select-row" || c?.key === "mark") {
                return c;
            }
            return {...c, renderHeaderCell: CustomHeaderCell};
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, columnGroup, report]);

    const onColumnsReorder = (sourceKey, targetKey) => {
        const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
        const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
        const reorderedColumns = [...columns];
        reorderedColumns.splice(targetColumnIndex, 0, reorderedColumns.splice(sourceColumnIndex, 1)[0]);
        setColumns(reorderedColumns);
        props.updateColumnSequence({sourceColumnKey: sourceKey, targetColumnKey: targetKey, columnGroup, report})
    };

    const getTableColumns = () => {
        let columns = [];
        props?.column?.data[columnGroup]?.forEach((col) => {
            if (!col?.hidden) {
                columns.push(col?.reportColumnKey);
            }
        });
        return columns;
    };

    const handleGetReportData = ({exportCSV = "", pageSize = 100}) => {
        gridRef?.current?.element?.scrollTo(0, 0);
        getReport(groupBy, from, to, timezone, name, tags, isDeleted, 1, pageSize, orderBy, sortBy, currencyCode, exportCSV, props?.metricFilter.filters, getTableColumns(), getWorkspaceIDs());
    };

    const handleInfinityScroll = (event) => {
        if (isLoading || !IsAtBottom(event)) {
            return;
        }

        let disable = data.page >= Math.ceil(data?.total?.totalRecords / data?.recordsPerPage);
        if (!disable) {
            loadMoreRows(groupBy, from, to, timezone, name, tags, isDeleted, data.page + 1, 100, orderBy, sortBy, currencyCode, props?.metricFilter.filters, getTableColumns(), getWorkspaceIDs());
        }
    };

    const handleRefresh = () => {
        gridRef?.current?.element?.scrollTo(0, 0);
        handleGetReportData({});
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleColumnResize = useCallback(debounce(updateColumnResize, 500), [columns, columnGroup, report]);

    const handleGroupBySelectedOptions = (trafficSourceName) => {
        let cache = GetLocalStorageCache("groupBySelect");
        if (cache !== null && cache !== undefined && cache !== "") {
            for (const item of cache) {
                if (item?.name === trafficSourceName) {
                    props.updateGroupBy1(item.g1);
                    props.updateGroupBy2(item.g2);
                    props.updateGroupBy3(item.g3);
                    return false;
                }
            }
        } else {
            cache = [];
        }

        cache.push({
            name: trafficSourceName,
            g1: props.groupBy1State?.groupBy,
            g2: props.groupBy2State?.groupBy,
            g3: props.groupBy3State?.groupBy,
        });
        SetLocalStorageCache("groupBySelect", cache)
    };

    const handleColumnRender = () => {
        let columns = [
            SelectColumn,
            MarkColumn,
            ...DynamicGridColumnCreator({columns: props?.column?.data[columnGroup]})
        ];
        setColumns(columns);
    };

    // 1. Generate grid columns dynamically at once and reset integration data.
    useEffect(() => {
        if (props?.column?.data !== null && columns === null) {
            props?.resetIntegration();
            handleColumnRender();
            handleRefresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.column?.data]);

    // 2. Get report data.
    useEffect(() => {
        if (columns !== null) {
            handleGetReportData({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, to, timezone, name, tags, isDeleted, sortBy, orderBy, currencyCode, props?.metricFilter?.filters]);

    // 3. Rerender
    useEffect(() => {
        if (rerender && columns !== null) {
            handleGetReportData({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rerender]);

    useEffect(() => {
        if (columns !== null) {
            handleColumnRender();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.column?.data]);

    // 4. Update OrderBy and SortBy values.
    useEffect(() => {
        if (sortColumns.length === 0) {
            updateSortBy("");
            updateOrderBy("");
            return () => {
            };
        }
        const {columnKey, direction} = sortColumns[0];
        updateOrderBy(columnKey);
        updateSortBy(direction);
    }, [updateOrderBy, updateSortBy, sortColumns]);

    // 5. Store Group By dropdown values to localstorage and set new values if campaign is selected.
    useEffect(() => {
        const rows = props.campaignsState.selectedRows;
        if (rows?.size === 1) {
            let trafficSourceName = "";
            props?.campaignsState?.data?.records?.forEach(item => {
                if (item.id === GetSelectedRowID(rows)) {
                    trafficSourceName = item?.trafficSourceName;
                }
            });
            handleGroupBySelectedOptions(trafficSourceName);
        }

        if (rows.size > 1 || rows.size === 0) {
            props.resetGroupBy1();
            props.resetGroupBy2();
            props.resetGroupBy3();
            return () => {};
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.campaignsState?.selectedRows])

    // 6. Rerender if user workspaces has changed.
    useEffect(() => {
        if (props?.workspace?.updated !== null) {
            handleGetReportData({});
            props?.resetUpdatedWorkspaceValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.workspace?.updated])

    return (
        <>
            <CustomToolbar
                columns={columns}
                selectedRows={selectedRows}
                onChangeToPage={props.rerender}
                searchTerm={name}
                onChangeSearch={props.updateName}
                tagsTerm={tags}
                onChangeTags={props.updateTags}
                onClickAdd={() => {
                    props.history.push({
                        pathname: "/campaigns/new",
                        state: {background: props.location}
                    });
                }}
                onClickRefresh={handleRefresh}
                onClickExportCSV={() => handleGetReportData({exportCSV: "csv", pageSize: 10000})}
                onClickEdit={(selectedRowId) => {
                    props.history.push({
                        pathname: "/campaigns/edit/" + selectedRowId,
                        state: {background: props.location}
                    });
                }}
                onClickDuplicate={(selectedRowId) => {
                    props.history.push({
                        pathname: "/campaigns/duplicate/" + selectedRowId,
                        state: {background: props.location}
                    });
                }}
                onClickArchive={(selectedRowsIds) => {
                    selectedRows.clear();
                    props.archive({ids: selectedRowsIds})
                }}
                onClickRestore={(selectedRowsIds) => {
                    selectedRows.clear();
                    props.restore({ids: selectedRowsIds})
                }}
                onClickCampaignLinks={(selectedRowId) => {
                    props.history.push({
                        pathname: "/campaigns/links/" + selectedRowId,
                        state: {background: props.location}
                    });
                }}
                onClickCampaignCost={(selectedRowId) => {
                    props.history.push({
                        pathname: "/campaigns/cost/" + selectedRowId,
                        state: {background: props.location}
                    });
                }}
                onSubmitChangeTrackingDomain={(data) => {
                    data.campaignIds = '' + GetCommaSeparatedList(selectedRows);
                    props?.changeTrackingDomain(data);
                }}
                onSubmitChangeWorkspace={(data) => {
                    data.campaignIds = '' + GetCommaSeparatedList(selectedRows);
                    props?.changeWorkspace(data);
                }}
                onlyCampaignView={true}
                report={report}
                columnGroup={columnGroup}
            />
            <Divider/>
            <CustomCampaignDataGridBox>

                {alert.errorMsg !== "" && <CustomSnackbar message={alert.errorMsg} timeout={3500}/>}
                {alert.successMsg !== "" &&
                    <CustomSnackbar message={alert.successMsg} severity="success" timeout={3500}/>}

                {(isLoading || columns === null) && <Spinner overComponentBox={true}/>}

                {columns !== null && classes !== null &&
                    <DataGrid
                        ref={gridRef}
                        rowKeyGetter={(row) => row?.id}
                        columns={modifiedTableColumns}
                        onColumnsReorder={onColumnsReorder}
                        rows={data?.records ? data.records : []}
                        defaultColumnOptions={{
                            sortable: true,
                            resizable: true,
                            draggable: true
                        }}
                        onColumnResize={handleColumnResize}
                        sortColumns={sortColumns}
                        onSortColumnsChange={updateSortColumns}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={setSelectedRows}
                        onSelectedCellChange={({rowIdx, row, column}) => {
                            if (rowIdx !== -1 && column?.key !== "select-row") {
                                if (props.groupBy1State.selectedRows.size > 0) {
                                    props.groupBy1State.selectedRows.clear();
                                }
                                if (props.groupBy2State.selectedRows.size > 0) {
                                    props.groupBy2State.selectedRows.clear();
                                }
                                setSelectedRows(new Set().add(row.id));
                            }
                        }}
                        onCellDoubleClick={props.handleDoubleClick}
                        onScroll={handleInfinityScroll}
                        noRowsFallback={<EmptyRowsRenderer/>}
                        summaryRowHeight={35}
                        bottomSummaryRows={[data]}
                        enableVirtualization={true}
                        className={classes?.dataGrid}
                    />
                }
            </CustomCampaignDataGridBox>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        campaignsState: state.campaign,
        groupBy1State: state.groupBy1,
        groupBy2State: state.groupBy2,
        groupBy3State: state.groupBy3,
        ddToolbarState: state.ddToolbar,
        auth: state.auth,
        alert: state.alert,
        metricFilter: state.metricFilter,
        column: state.column,
        workspace: state.workspace
    }
};

const actionCreators = {
    getReport: campaignActions.getReport,
    loadMoreRows: campaignActions.loadMoreRows,
    updateOrderBy: campaignActions.updateOrderBy,
    updateSortBy: campaignActions.updateSortBy,
    updateName: campaignActions.updateName,
    updateTags: campaignActions.updateTags,
    updateSortColumns: campaignActions.updateSortColumns,
    archive: campaignActions.archive,
    restore: campaignActions.restore,
    setSelectedRows: campaignActions.setSelectedRows,
    updateGroupBy1: groupBy1Action.updateGroupBy,
    resetIntegration: groupBy1Action.resetIntegration,
    resetGroupBy1: groupBy1Action.reset,
    updateGroupBy2: groupBy2Action.updateGroupBy,
    resetGroupBy2: groupBy2Action.reset,
    updateGroupBy3: groupBy3Action.updateGroupBy,
    resetGroupBy3: groupBy3Action.reset,
    updateColumnWidth: columnActions.updateColumnWidth,
    updateColumnSequence: columnActions.updateColumnSequence,
    getWorkspaceIDs: workspaceActions.getWorkspaceIDs,
    resetUpdatedWorkspaceValue: workspaceActions.resetUpdatedWorkspaceValue,
    changeTrackingDomain: campaignActions.changeTrackingDomain,
    changeWorkspace: campaignActions.changeWorkspace,
};

export default compose(connect(mapStateToProps, actionCreators))(CampaignsGrid);