import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    useTheme,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Switch
} from "@mui/material";
import PropTypes from "prop-types";
import {compose} from "redux";
import {connect} from "react-redux";
import {columnActions} from "../../store/actions/columnAction";

function ColumnSettings(props) {
    const {onClose, columns, open, report, columnGroup} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleOnChange = (column) => {
        let hidden = true;
        if (column.hidden) {
            hidden = false;
        }

        for (const col of columns) {
            if (col?.key === column?.columnKey) {
                col.hidden = hidden;
            }
        }
        props.updateColumnVisibility({columnID: column?.id, hidden: hidden, columnGroup, report});
    };

    const handleOnClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        onClose();
    };

    return (
        <Dialog open={open}
                onClose={handleOnClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="column-settings"
        >
            <DialogTitle sx={{textAlign: "center"}}>Column settings</DialogTitle>
            <DialogContent>
                <List>
                    {/* eslint-disable-next-line array-callback-return */}
                    {props?.state?.column?.data[columnGroup]?.map((item) => {
                        if (item?.columnKey !== "name") {
                            return (
                                <ListItem key={item?.id}>
                                    <ListItemText id={item?.id} primary={item?.title}/>
                                    <ListItemSecondaryAction>
                                        <Switch
                                            edge="end"
                                            onChange={() => handleOnChange(item)}
                                            checked={!item?.hidden}
                                            inputProps={{'aria-labelledby': item?.id}}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        }
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnClose} color="inherit" variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ColumnSettings.prototype = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    columns: PropTypes.array.isRequired,
    report: PropTypes.string.isRequired,
    columnGroup: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    return {
        state: {
            column: state.column
        }
    }
};

const actionCreators = {
    updateColumnVisibility: columnActions.updateColumnVisibility,
};

export default compose(connect(mapStateToProps, actionCreators))(ColumnSettings);