import {
    Box,
    Button,
    Chip,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    RadioGroup, Switch,
    TextField,
    Radio, useTheme
} from "@mui/material";
import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import RedirectMode from "./RedirectMode";
import PathOffers from "./PathOffers";
import PathLandings from "./PathLandings";
import RuleBasedPaths from "./RuleBasedPaths";
import DefaultPaths from "./DefaultPaths";
import {connect} from "react-redux";
import {campaignFormActions} from "../../store/actions/campaignFormAction";
import PathRules from "./PathRules";
import {alertActions} from "../../store/actions/alertActions";
import FlowSelect from "../flows/FlowSelect";
import {flowActions} from "../../store/actions/flowAction";
import {campaignActions} from "../../store/actions/campaignsAction";
import {ChevronRight, ExpandMore} from "@mui/icons-material";
import {TreeView} from '@mui/x-tree-view/TreeView';
import {TreeItem} from '@mui/x-tree-view/TreeItem';

function FormStep3(props) {

    const theme = useTheme();
    const {handleClose, handleBack, actionName, clearErrorMsg, errorMsg, updateDestinationType} = props;
    const {
        flow, selectedRuleNumber, selectedPathNumber, destinationUrl, selectedRule, destinationType
    } = props.campaignFormState;

    const {register, handleSubmit, control, watch, setValue, formState: {errors}} = useForm({
        defaultValues: props.campaignFormState
    });

    const updatePathDirectLinking = (selectedRuleNumber, selectedPathNumber, checked) => {
        props.updatePathDirectLinking(selectedRuleNumber, selectedPathNumber, checked);
    };

    useEffect(() => {
        if (actionName === "update" || actionName === "duplicate") {
            setTimeout(() => {
                setValue("destinationUrl", destinationUrl);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [destinationUrl, actionName]);

    const onSubmit = (formData) => {
        props.formStep3(formData);

        let flowState = props.flowState;
        let campaignState = props.campaignFormState;

        let campaignData = {
            workspaceId: campaignState?.workspace?.value,
            name: campaignState?.name,
            cloakingDomainId: formData?.cloakingDomain?.value ? formData.cloakingDomain.value : 0,
            costModel: campaignState?.costModel ? campaignState.costModel : "",
            costValue: campaignState?.costValue ? parseFloat(campaignState.costValue) : 0,
            countryCode: campaignState?.country?.code ? campaignState.country.code : "",
            currencyCode: campaignState?.currency?.code ? campaignState.currency.code : "",
            destinationType: formData?.destinationType ? formData.destinationType : "",
            destinationUrl: formData?.destinationUrl ? formData.destinationUrl : "",
            redirectMode: formData?.redirectMode ? formData.redirectMode : "",
            trackingDomainId: campaignState?.trackingDomain?.value ? campaignState.trackingDomain.value : 0,
            trafficID: campaignState?.trafficSource?.value ? campaignState.trafficSource.value : 0,
            conversionActionId: campaignState?.conversionAction?.id ? campaignState?.conversionAction?.id : 0,
            conversionPixelId: campaignState?.conversionPixel?.id ? campaignState?.conversionPixel?.id : 0,
            flow: campaignState?.flow,
            tags: campaignState?.tags
        };

        if (flowState !== null && destinationType === "sharedFlow") {
            campaignData.flowId = flowState?.sharedFlow?.value;
            campaignData.flow = null;
        }

        clearErrorMsg();
        let ruleName = "";
        let pathName = "";
        let valid = true;

        if (campaignData?.destinationType === "flow") {
            for (const rule of flow?.rules) {
                for (const path of rule?.paths) {
                    if (path?.directLinking && path?.status) {
                        for (const offer of path?.offers) {
                            if (offer?.offerId === 0) {
                                valid = false;
                                break;
                            }
                        }
                    } else if (!path?.directLinking && path?.status) {
                        for (const landing of path?.landings) {
                            if (landing?.landingId === 0) {
                                valid = false;
                                break;
                            }
                        }
                        for (const offer of path?.offers) {
                            if (offer?.offerId === 0) {
                                valid = false;
                                break;
                            }
                        }
                    }
                    if (!valid) {
                        pathName = path?.name;
                        break;
                    }
                }
                if (!valid) {
                    ruleName = rule?.name;
                    break;
                }
            }

            if (valid === false) {
                errorMsg("Please select the missing offers and landings in rule named: " + ruleName + " and path named: " + pathName);
                return false;
            }
        }

        if (actionName === "update") {
            props.update(campaignState?.id, campaignData);
        } else {
            props.create(campaignData);
        }

        props.rerender();
    };

    const handleChipClick = (item) => {
        setValue("destinationUrl", watch("destinationUrl") + item);
    };

    const urlChip = (name) => {
        return (
            <Chip
                label={name}
                color="primary"
                size="small"
                sx={{fontSize: "12px"}}
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => handleChipClick(name)}
                disabled={!!watch("destinationUrl").includes(name)}
            />
        );
    };

    const getTotalWeight = (items) => {
        let totalPathWeight = 0;
        items.forEach(item => {
            totalPathWeight = totalPathWeight + item.weight;
        });
        return totalPathWeight;
    };

    const getCurrentWeight = (weight, items) => {
        return Math.round(parseInt(weight) / getTotalWeight(items) * 100);
    };

    const renderRadioButton = (value, label) => {
        return (
            <FormControlLabel
                value={value}
                control={<Radio onChange={() => updateDestinationType(value)} color="primary"/>}
                checked={destinationType === value}
                label={label}
            />
        );
    };

    const generateRandomString = () => {
        return (Math.random() + 1).toString(36).substring(7);
    };

    const renderSharedFlowDropdown = () => {
        return (
            <Box display="flex" flexDirection="column" mt={2}>
                <Box sx={{
                    padding: "0px",
                    margin: "0px",
                    fontSize: "14px",
                    fontWeight: 600
                }}>
                    Load flow from templates
                </Box>
                <Box mt={1}>
                    <FlowSelect
                        control={control}
                        errors={errors}
                        inputName="flow"
                        inputValue={props?.flowState?.sharedFlow}
                        countryCode={props?.campaignFormState?.country?.code}
                        updateSelection={props?.updateSharedFlow}
                        setValue={setValue}
                    />
                </Box>
            </Box>
        );
    };

    const customTreeViewLabel = (name, weight, arr) => {
        return (
            <Box display="flex" flexDirection="row" flexWrap="noWrap">
                <Box flexGrow={1}>{name}</Box>
                <Box display="flex" flexDirection="row" sx={{minWidth: "100px", textAlign: "right"}} flexWrap="noWrap">
                    <Box sx={{paddingRight: "8px"}}>
                        {weight}
                    </Box>
                    <Box sx={{color: "primary.main"}}>
                        ({getCurrentWeight(weight, arr)})%
                    </Box>
                </Box>
            </Box>
        );
    };

    const renderTreeViewItem = (arr, label) => {
        return (
            <TreeItem
                nodeId={generateRandomString()}
                label={label}
                sx={{
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    fontSize: "14px",
                    fontWeight: 400,
                    paddingBottom: 1
                }}
            >
                {arr?.map(item => {
                    if (item?.name === "") {
                        return null;
                    }
                    return (
                        <TreeItem
                            nodeId={item?.name + generateRandomString()}
                            label={customTreeViewLabel(item?.name, item?.weight, arr)}
                            key={generateRandomString()}
                            sx={{
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                                fontSize: "14px",
                                fontWeight: 400,
                                paddingBottom: 1
                            }}
                        />
                    );
                })}
            </TreeItem>
        );
    };

    const renderSharedFlowTreeView = () => {
        let flowObj = [];
        if (props?.flowState?.sharedFlow?.flow) {
            flowObj = JSON.parse(props?.flowState?.sharedFlow?.flow);
        }

        return (
            <>
                <Divider orientation="vertical" flexItem sx={{
                    width: "2px",
                    margin: "0 24px",
                    [theme.breakpoints.down(1250)]: {
                        display: "none",
                        margin: "0px",
                    }
                }}/>
                <Box display="flex" flexDirection="column" flexWrap="wrap" sx={{
                    minWidth: "50%",
                    maxWidth: "50%",
                    [theme.breakpoints.down(1250)]: {
                        width: "100%",
                        maxWidth: "100%",
                    }
                }}>
                    <TreeView
                        sx={{paddingBottom: "2px", fontSize: "16px"}}
                        defaultCollapseIcon={<ExpandMore/>}
                        defaultExpandIcon={<ChevronRight/>}
                        disableSelection
                    >
                        {
                            flowObj?.rules?.map(rule => {
                                return (
                                    <TreeItem
                                        nodeId={generateRandomString()}
                                        label={"Rule - " + rule?.name}
                                        key={generateRandomString()}
                                        sx={{
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            paddingBottom: 1
                                        }}
                                    >
                                        {rule?.paths.map(path => {
                                            return (
                                                <TreeItem
                                                    nodeId={generateRandomString()}
                                                    label={customTreeViewLabel("Path - " + path?.name, path?.weight, rule?.paths)}
                                                    key={generateRandomString()}
                                                    sx={{
                                                        wordWrap: "break-word",
                                                        whiteSpace: "normal",
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        paddingBottom: 1
                                                    }}
                                                >
                                                    {renderTreeViewItem(path?.landings, "Landings")}
                                                    {renderTreeViewItem(path?.offers, "Offers")}
                                                </TreeItem>
                                            );
                                        })}
                                    </TreeItem>
                                );
                            })
                        }
                    </TreeView>
                </Box>
            </>
        );
    };

    return (
        <>
            <DialogContent sx={{minHeight: "550px"}}>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
                    <Box display="flex" flexWrap="wrap">
                        <Box
                            flexGrow={1}
                            sx={{
                                minWidth: "350px",
                                marginBottom: "20px",
                                marginRight: "20px",
                                [theme.breakpoints.down(600)]: {
                                    marginRight: "0px",
                                }
                            }}
                        >
                            <Box display="flex" flexDirection="column" mt={2}>
                                <Box sx={{padding: "0px", margin: "0px", fontSize: "14px", fontWeight: 600}}>
                                    Destination type
                                </Box>
                                <Box>
                                    <Controller
                                        render={({field: {onChange, value}}) => (
                                            <RadioGroup
                                                row
                                                aria-label="destinationType"
                                                defaultValue="flow"
                                                value={value}
                                                onChange={(e) => onChange(e.target.value)}
                                            >
                                                {renderRadioButton("flow", "Flow")}
                                                {renderRadioButton("sharedFlow", "Shared flow")}
                                                {renderRadioButton("url", "URL")}
                                            </RadioGroup>
                                        )}
                                        control={control}
                                        name="destinationType"
                                        defaultValue="url"
                                    />
                                </Box>
                            </Box>

                            <RedirectMode watch={watch} control={control} errors={errors}/>

                            {destinationType === "url" &&
                                <Box display="flex" flexDirection="column" mt={1}>
                                    <Box>
                                        <TextField
                                            error={!!errors.destinationUrl}
                                            variant="outlined"
                                            margin="dense"
                                            {...register("destinationUrl", {
                                                required: true,
                                                pattern: /^https?:\/\/(www\.)?[-a-zA-Z\d@:%._+~#=]{1,512}\.[a-zA-Z\d()]{1,20}\b([-a-zA-Z\d()@:%_+.~#?&/=]*)/
                                            })}
                                            size="small"
                                            name="destinationUrl"
                                            label="Destination URL*"
                                            placeholder="e.g. https://example.com"
                                            type="text"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{marginTop: "18px", marginBottom: "12px"}}
                                        />
                                    </Box>
                                    <Box>
                                        {urlChip("{clickId}")}
                                        {urlChip("{externalId}")}
                                        {urlChip("{campaignId}")}
                                        {urlChip("{campaignName}")}
                                        {urlChip("{trafficSourceId}")}
                                        {urlChip("{trafficSourceName}")}
                                        {urlChip("{deviceType}")}
                                        {urlChip("{deviceVendor}")}
                                        {urlChip("{deviceModel}")}
                                        {urlChip("{browser}")}
                                        {urlChip("{browserVersion}")}
                                        {urlChip("{os}")}
                                        {urlChip("{osVersion}")}
                                        {urlChip("{ip}")}
                                        {urlChip("{isp}")}
                                        {urlChip("{country}")}
                                        {urlChip("{state}")}
                                        {urlChip("{city}")}
                                        {urlChip("{lang}")}
                                        {urlChip("{userAgent}")}
                                        {urlChip("{trackingDomain}")}
                                        {urlChip("{referrerDomain}")}
                                        {urlChip("{custom1}")}
                                        {urlChip("{custom2}")}
                                        {urlChip("{custom3}")}
                                        {urlChip("{custom4}")}
                                        {urlChip("{custom5}")}
                                        {urlChip("{custom6}")}
                                        {urlChip("{custom7}")}
                                        {urlChip("{custom8}")}
                                        {urlChip("{custom9}")}
                                        {urlChip("{custom10}")}
                                        {urlChip("{custom1Hash}")}
                                        {urlChip("{custom2Hash}")}
                                        {urlChip("{custom3Hash}")}
                                        {urlChip("{custom4Hash}")}
                                        {urlChip("{custom5Hash}")}
                                        {urlChip("{custom6Hash}")}
                                        {urlChip("{custom7Hash}")}
                                        {urlChip("{custom8Hash}")}
                                        {urlChip("{custom9Hash}")}
                                        {urlChip("{custom10Hash}")}
                                    </Box>
                                </Box>
                            }

                            {destinationType === "sharedFlow" && renderSharedFlowDropdown()}

                            {destinationType === "flow" &&
                                <>
                                    <DefaultPaths getCurrentWeight={getCurrentWeight}/>
                                    <RuleBasedPaths getCurrentWeight={getCurrentWeight}/>
                                </>
                            }
                        </Box>

                        {destinationType === "sharedFlow" && renderSharedFlowTreeView()}

                        {(destinationType === "flow" && selectedRule !== null) &&
                            <Box flexGrow={3} sx={{minWidth: "634px", maxWidth: "634px"}} p={0}>
                                <PathRules/>
                            </Box>
                        }

                        {(destinationType === "flow" && selectedRule === null) &&
                            <Box flexGrow={3} sx={{minWidth: "634px"}} p={0}>
                                <Box sx={{paddingBottom: "20px"}}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={flow?.rules[selectedRuleNumber]?.paths[selectedPathNumber]?.directLinking}
                                                color="primary"
                                                onChange={(e, checked) => updatePathDirectLinking(selectedRuleNumber, selectedPathNumber, checked)}
                                                name="directLinking"
                                            />
                                        }
                                        label="Use only offers"
                                    />
                                </Box>
                                <Box display="flex" flexDirection="column">
                                    {
                                        !flow?.rules[selectedRuleNumber]?.paths[selectedPathNumber]?.directLinking &&
                                        <PathLandings
                                            control={control}
                                            register={register}
                                            errors={errors}
                                            getCurrentWeight={getCurrentWeight}
                                            setValue={setValue}
                                        />
                                    }
                                    <PathOffers
                                        control={control}
                                        register={register}
                                        errors={errors}
                                        getCurrentWeight={getCurrentWeight}
                                        setValue={setValue}
                                    />
                                </Box>
                            </Box>
                        }
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit" variant="outlined" ml={2}>
                    Cancel
                </Button>
                <Button onClick={handleBack} color="inherit" variant="contained">
                    Back
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                    {actionName === "update" ? "Update" : "Save"}
                </Button>
            </DialogActions>
        </>
    );
}

const mapStateToProps = (state) => ({
    campaignFormState: state.campaignForm,
    flowState: state.flow,
});

const mapDispatchToProps = {
    updatePathDirectLinking: campaignFormActions.updatePathDirectLinking,
    rerender: campaignActions.rerender,
    formStep3: campaignFormActions.formStep3,
    create: campaignFormActions.create,
    update: campaignFormActions.update,
    updateDestinationType: campaignFormActions.updateDestinationType,
    updateSharedFlow: flowActions.updateSharedFlow,
    errorMsg: alertActions.error,
    clearErrorMsg: alertActions.clear
};

export default connect(mapStateToProps, mapDispatchToProps)(FormStep3);